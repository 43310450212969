import React from 'react';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import Dashboard from  "../../components/dashboard/dashboard.component";

const The9thSDGPage = () => {
	return (
		<Layout>
			<SEO title={"Industry, innovation and infrastructure"}/>
			<h1 className={"title"}>Industry, Innovation and Infrastructure</h1>
			<Dashboard/>
		</Layout>
	)
};

export default The9thSDGPage;
